import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Link } from '@chakra-ui/react'
import LinkReactRouter from './link'
import Slider from 'react-slick'

const Hero = ({ ...props }) => {
  const altValentines2 =
    "Valentine's Day pie gift boxes – award-winning, handcrafted pies in boxes of 4, 6, 8, or 12. Treat your loved ones to delicious flavors while saving money. Each pie made with love and packed with care!"
  const urlValentines2 = '/collections/all-special-occasions-giftboxes'
  const altValentines =
    "Valentine's Day message with the text: 'HAVE A LOVELY VALENTINES DAY, WE ONLY HAVE PIES* FOR YOU.'"
  const altHearty =
    'Limited edition hearty beef and potato pie – back for a short time only, grab it while it lasts!'
  const urlHearty = '/products/hearty-beef-and-potato-pie'
  const urlhb = '/products/hearty-beef-and-potato-pie'
  const hbtitle = `Thank you Mud Foods for my delivery of meat and potato pies today. We had two for tea and they were beautiful - the pastry was delicious and the filling was absolutely full to the brim. Definitely be ordering again."r). Discount applied automatically. Free delivery on orders of £45 (and over),`

  const clickedBannerEvent = () => {
    if (
      process.env.NODE_ENV === `production` &&
      typeof _learnq.push === `function` // eslint-disable-line no-undef
    ) {
      // prettier-ignore
      _learnq.push([// eslint-disable-line no-undef
        
        'track',
        'Clicked Banner',
        {
          SourceURL: 'https://mudfoods.com/',
          DestinationURL: 'https://mudfoods.com' + urlhb,
          BannerTitle: hbtitle,
        },
      ])
    }
  }

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
  }
  const settingsMobile = {
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    adaptiveHeight: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 0,
        settings: 'unslick',
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'inline-block !important',
          '@media screen and (min-width: 640px)': {
            display: 'none !important',
          },
        }}
      >
        <Slider {...settingsMobile}>
          {/* <Link
            to={urlHearty}
            key="Hearty"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/hearty2025/mobile/01.jpg"
              alt={altHearty}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          <Link
            to={urlValentines2}
            key="Valentines2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              key="Valentines2"
              src="../images/heros/valentine-2-2025/mobile/01.jpg"
              alt={altValentines2}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <StaticImage
            key="Valentines"
            src="../images/heros/valentine2025/mobile/01.jpg"
            alt={altValentines}
            objectFit="cover"
            placeholder="dominantColor"
            formats={['auto', 'webp']}
            loading="eager"
          />
        </Slider>
      </Container>
      <Container
        pr={[0]}
        pl={[0]}
        // mb={[4]}
        position="relative"
        {...props}
        sx={{
          display: 'none !important',
          '@media screen and (min-width: 640px)': {
            display: 'inline-block !important',
          },
        }}
      >
        <Slider {...settingsMain}>
          {/* <Link
            to={urlHearty}
            key="HeartyFlash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              src="../images/heros/hearty2025/desktop/01.jpg"
              alt={altHearty}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link> */}
          <Link
            to={urlValentines2}
            key="Valentines2Flash2"
            as={LinkReactRouter}
            onClick={clickedBannerEvent}
          >
            <StaticImage
              key="ValentinesFlash2"
              src="../images/heros/valentine-2-2025/desktop/01.jpg"
              alt={altValentines2}
              objectFit="cover"
              placeholder="dominantColor"
              formats={['auto', 'webp']}
              loading="eager"
            />
          </Link>
          <StaticImage
            key="ValentinesFlash"
            src="../images/heros/valentine2025/desktop/01.jpg"
            alt={altValentines}
            objectFit="cover"
            placeholder="dominantColor"
            formats={['auto', 'webp']}
            loading="eager"
          />
        </Slider>
      </Container>
    </>
  )
}

export default Hero
